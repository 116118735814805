import styled from "styled-components"

export const TOCBox = styled.div`
    border: 1px solid #ccc;
    margin: 4px;
    padding: 2px 6px;
    background-color: #ddd;
`

export const Header = styled.h6`
    margin: 6px 4px;
    padding: 2px 6px;
`

// export const Header = styled.h6`
//     margin: 6px 4px;    
//     padding: 2px 6px;
// `