import React from "react"
import { Wrapper } from "./styles"

// this provides metaData (around the Children prop). For example a "location URL" or "title"
export const AssetMetaDataBox = ({ title, locationUrl, children}) => {
  console.log("title #[fg6]: ", title)
  return (
    <Wrapper>
      {children}
      {title && (
        <h6>{title}</h6>
      )}
      {locationUrl && (
        <>Location: <a target="_new" href={locationUrl}>Google Maps</a></>
      )}
    </Wrapper>
  )
}