import styled from "styled-components"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

export const ColHeader = styled(Col)`
    
`
export const ColBody = styled(Col)`
    margin: 2px 0px;
    // padding: 0px;
`

export const Margined = styled.div`
    margin: 2px;
    padding: 2px 6px;
    background-color: #eee;
    font-weight: bold;
`

export const TransportContainer = styled(Container)`
    margin: 0px;
    padding: 0px;
`

