// returns something like: "12 hours, 30 minutes"
export const getHumanReadableDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    // console.log('hours [#ghd]: ', hours, ', minutes: ', minutes)
  
    const hourString = (hours) ? `${hours} hours` : ''
    const minuteString = (minutes) ? `${minutes} mins` : ''
  
    // console.log('hourString [#ghd]: ', hourString, ', minuteString: ', minuteString)
  
    if (hourString && minuteString) {
      return hourString + ', ' + minuteString
    }
    
    if (hourString) {
      return hourString 
    } else {
      return minuteString
    }
    
  }