import React from "react"
import Row from "react-bootstrap/Row"
import { ColBody, ColHeader as ColModified, Margined, TransportContainer } from "./styles"
import { ProductBox } from "../../ProductBox"
import { reduceStringToNameAttribute } from "../RichText/reduceStringToNameAttribute"

export const TOCaption = 'How to get here'

export const TOAnchorName = reduceStringToNameAttribute(TOCaption)

const ColHeader = ({ children }) => {
  return (
    <ColModified>
      <Margined>
        {children}
      </Margined>
    </ColModified>
  )
}

export const TransportOptions = ({ data }) => {
  console.log('in TransportOptions: data: ', data)
  return (
    <div>
      <h3>
        <a name={TOAnchorName}>{TOCaption}:</a>
      </h3>
      <TransportContainer className="gx-20">
          {/* <Row>
            <Col xs={12} md={12} lg={12}>
              <h6>How to get here:</h6>
            </Col>
          </Row> */}
          <Row>
            <ColHeader xs={12} md={6} lg={6}>
              Self drive:
            </ColHeader>
            <ColBody xs={12} md={6} lg={6}>
              {data.selfDrive}
            </ColBody>
          </Row>
          <Row>
            <ColHeader xs={12} md={6} lg={6}>
              Public transport:
            </ColHeader>
            <ColBody xs={12} md={6} lg={6}>
            {data.publicTransport}
            </ColBody>
          </Row>
          <Row>
            <ColHeader xs={12} md={12} lg={12}>
            Our Trips:
            </ColHeader>
            <ColBody xs={12} md={12} lg={12}>
              Below is a list of trips we offer that visit this destination / location. You're welcome to join us :-)
              <OurProducts productsList={data.ourProducts} />
            </ColBody>
          </Row>
        </TransportContainer>
    </div>
  )
}

const OurProducts = ({ productsList }) => {
  return productsList.map(product => {
    return (
     <ProductBox product={product} />
    )
  })
}