import React from "react"
import { Header, TOCBox } from "./styles"

export const TableOfContents = ({ items }) => {
  console.log('TableOfContents items: ', items)
  if (items.length == 0) {
    return (<></>)
  }

  return (
      <TOCBox>
          <Header>Overview:</Header>
          <ul>
            {items.map((curItem) => {
              console.log('curItem: ', curItem)
              return (
                  <li>
                    <a href={"#" + curItem.name}>{removeColonIfExists(curItem.value)}</a>
                  </li>
              )
            })}
          </ul>
      </TOCBox>
  )
}

function removeColonIfExists(text) {
  if (text && text.endsWith(':')) {
    return text.slice(0, -1); // Remove the last character (colon)
  } else {
    return text;
  }
}