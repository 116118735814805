import React from "react"
import { AssetMetaDataBox } from "../AssetMetaDataWrapper"
import { GatsbyImage } from "gatsby-plugin-image"

export const ContentfulImageExtended = ({ locationUrl, imageData, title}) => {
  console.log("imageData [er2]: ", imageData)
  console.log("locationUrl [er2]: ", locationUrl)

  return (
    <AssetMetaDataBox locationUrl={locationUrl} title={title}>
      <GatsbyImage image={imageData} />
    </AssetMetaDataBox>
  )
}