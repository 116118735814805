import React from "react"
import { TikTokEmbed } from "react-social-media-embed"
import { AssetMetaDataBox } from "../AssetMetaDataWrapper"

export const SocialPost = ({ ttUrl, title, locationUrl }) => {
    return (
      <AssetMetaDataBox title={title} locationUrl={locationUrl}>
        <TikTokEmbed url={ttUrl} />
      </AssetMetaDataBox>
    )
}