import * as React from "react"
import { CalculateDiscount } from "../../functions/CalculateDiscount"
import { ProductContainer, ProductLink, Row, Col, WithoutDiscount } from "./styles.js"
import ContentfulImage from "../../components/ContentfulImage"
import getSlug from "../../functions/getSlug"
import { getHumanReadableDuration } from "../../functions/getHumanReadableDuration"
import { CancelledNotice } from "../components/EventDate"

// Return a string similar to: '12 hours, 0 minutes' or "no length of activity provided"
const getLengthInTimeAsString = (node) => {
    // use the next sessions 'lengtInTime'
    if (!node.durationInMinutes) {
      return "no length of activity provided"
    }
  
    return getHumanReadableDuration(node.durationInMinutes)
}

export const ProductBox = ({ product, additionalJSX, cancelled }) => {
    // console.log('product [#dh7]: ', product)
    const productUrl = getSlug(product.urlSlug)
    const { finalRateAsString, withoutDiscountString } = CalculateDiscount(product.sellPrice, 13)

    // return 'sdfg'
    const description = (cancelled) ? (
        <>
            <CancelledNotice />
            &nbsp;This event will not run!
        </>
    ): product.descriptionShort
  
    return (
        <ProductLink to={productUrl}>
            <ProductContainer>
                <Row>
                    <Col className="col left" xs={12} md={3} lg={3}>
                        <ContentfulImage.CropIn image={product.featureImage} />
                    </Col>
                    <Col className="col middle padded" xs={12} md={7} lg={7}>
                        {additionalJSX}
                    <h3>
                        {product.name}
                    </h3>
                    {description}
                    <br />
                    <span className="duration">Duration:</span>{" "}
                    {getLengthInTimeAsString(product)}
                    </Col>
                    <Col
                        className="col right padded align-items-center"
                        xs={12} md={2} lg={2}
                    >
                    {/* <PricingContainer>
                        <Row>
                        <Col class="col" xs={12} md={12} lg={12}> */}
                            From:
                            <br />
                            <div className="price">
                            {finalRateAsString}
                            <WithoutDiscount>{withoutDiscountString}</WithoutDiscount>
                            </div>
                        {/* </Col>
                        </Row>
                    </PricingContainer> */}
                    </Col>
                </Row>
            </ProductContainer>
        </ProductLink>
    )
}