import React, { useState } from "react"
import { Box, Link } from "./styles";

// displays only on urls with: "UltimateGuideToMelbourne"
const UltimateGuideToMelbourneHeader = () => {
    const [ isVisible, setIsVisible ] = useState(0)

    let hasString = false
    if (typeof window !== 'undefined') {
      const url = window.location.href
      hasString = url.includes("UltimateGuideToMelbourne")
    }
  
    const toggleVisible = () => {
      setIsVisible (() => {return 1 - isVisible})
    }
  
    return (
      <div>
        {hasString ? (
          <>
            <Box>
              This article is part of our: <Link onClick={() => toggleVisible()}><u><b>Ultimate Guide To Melbourne!</b></u></Link> series.
              {(isVisible == 1) &&
              <div>
                  <b>Overview:</b>
                  <br />The <u>Ultimate Guide To Melbourne!</u> is a list of the best free events, short roadtrips and experiences in (and from) Melbourne.
                  <br /><b>We focus on:</b>
                  <ul>
                    <li>FREE and highly affordable experiences / events</li>
                    <li>Highly instagrammable photo spots</li>
                    <li>Providing exact locations with our photos and videos (so you can find the same spot when you visit)</li>
                  </ul>
              </div>
            }
            </Box>
          </>
          
        ) : (
          <></>
        )}
      </div>
    );
  }

export default UltimateGuideToMelbourneHeader