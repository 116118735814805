import styled from "styled-components"

export const Box = styled.div`
    border: 1px solid #0960ff;
    background-color: #b4d6ff;
    padding: 2px 6px;
    margin: 5px;
`

export const Link = styled.a`
    cursor:pointer;
`