import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"

import { RichText } from "../_shared/Contentful/RichText";
import UltimateGuideToMelbourneHeader from "../_NTM_site/UltimateGuideToMelbourneHeader";
import GatsbyImage from "../components/GatsbyImage";
import ContentfulImage from "../components/ContentfulImage";

export const designPageUrl = 'https://www.figma.com/file/fT9N9u8AVBDUCUHRjOjF7J/NTM-%2F-OTA-website?node-id=153%3A2'

const IndexPage = ({ data }) => {
  console.log("in template: simplePage.js [#3fr]. data: ", data);
  console.log('data.allContentfulSimplePage.nodes[0].featureImage: ', data.allContentfulSimplePage.nodes[0].featureImage)

  const simplePage = data.allContentfulSimplePage.nodes[0]
  const references = simplePage.body.references

  const content = simplePage.body

  const featureImage = data.allContentfulSimplePage.nodes[0].featureImage
  const FeatureImageComp = ( { featureImage }) => {
    if (!featureImage) { return null }

    const image = featureImage.image
    return (
      <ContentfulImage.BlockVersion image={ image } noImageMsg={ image.description } />
    )
  }
  console.log('featureImage: ', featureImage)
  
  return (
    <Layout>
      <UltimateGuideToMelbourneHeader />
      <h2>{simplePage.title}</h2>
      <FeatureImageComp featureImage={featureImage} />
      <RichText raw={content.raw} references={references} />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query getSimplePageByContentfulId($contentfulEntryId: String) {
    allContentfulSimplePage(filter: {id: {eq: $contentfulEntryId}}) {
      nodes {
        title
        id
        contentful_id
        menuCaption
        featureImage {
          image {
            contentful_id
            gatsbyImageData
            description
            title
          }
        }
        body {
          references {
            ... on ContentfulSocialPost {
              __typename
              name
              tiktokPostId
              id
              contentful_id
              googleMapsLink
            }
            ... on ContentfulSimplePage {
              __typename
              id
              title
              contentful_id
              urlSlug
            }
            ... on ContentfulAsset {
              __typename
              id
              contentful_id
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
            ... on ContentfulImageExtended {
              __typename
              id
              locationUrl
              contentful_id
              image {
                gatsbyImageData
                description
                title
                contentful_id
              }
            }
            ... on ContentfulTransportOptions {
              __typename
              id
              contentful_id
              selfDrive
              publicTransport
              ourProducts {
                name
                id
                descriptionShort
                durationInMinutes
                sellPrice
                urlSlug {
                  productName
                  contentful_id
                  startLocation {
                    contentful_id
                    parentLocation {
                      title
                      id
                      contentful_id
                      parentLocation {
                        contentful_id
                        id
                        title
                        parentLocation {
                          contentful_id
                          id
                          title
                        }
                      }
                    }
                    id
                    title
                  }
                }
                featureImage {
                  gatsbyImageData
                  title
                }
              }
            }
          }
          raw
        }
        
      }
    }
    
  }
`