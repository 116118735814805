
export function reduceStringToNameAttribute(string1) {
    // console.log(string1)
    // console.log('typeof string1: "', typeof String + '"')
    console.log('string1: ', typeof string1)
    if (!(typeof string1 === 'string')) { throw new Error ('string1 must be a string.')}
    
    // Replace multiple whitespace characters with a single hyphen
    const reduced = string1.replace(/\s+/g, '-');
  
    // Remove any non-alphanumeric characters except hyphens and dots
    const allowedChars = /[^a-zA-Z0-9\-\.]/g;
    const name = reduced.replace(allowedChars, '');
  
    // Convert to lowercase
    return name.toLowerCase();
  }
