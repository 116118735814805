import React from "react"
import Img from "gatsby-image"

const GatsbyImage = ( { image } ) => {
    // console.log ('image (#sfs3223edf) ', image)
    if (!image) {
        // error
        return (
            'no image provided.'
        )
    } else {
        const imageFluid = image.childImageSharp.fluid;
        return (
            <Img fluid={ imageFluid } />
        )
    }
}

export default GatsbyImage