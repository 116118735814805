import React, { useEffect, useState } from "react"
import { BLOCKS, INLINES, helpers } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { GatsbyImage } from "gatsby-plugin-image";
import { SocialPost } from "../SocialPost";
import { Experience, TOAnchorName, TOCaption, TransportOptions } from "../TransportOptions";
import { TOCCaption, TableOfContents } from "../TableOfContents";
import { reduceStringToNameAttribute } from "./reduceStringToNameAttribute";
import { ContentfulImageExtended } from "../ContentfulImageExtended";

export const anchorNamePrefix = 'TOC_anchor_'
// render the rich text and any embeds
export const RichText = ({ raw, references }) => {
    const [TOC, setTOC] = useState([])
    // stop recursion of useState
    
    // Set index as contentful_id
    const referencesMap = {}
    references.forEach(reference => {
        const referenceID = reference.contentful_id
        if (!referenceID) { 
            console.log('references [df5]: ', references)
            console.log('problem reference [ry8]: ', reference)
            throw new Error ('contentful_id must be set in order to build a referenceMap. It is: ' + referenceID + '. Check your graphQL query contains this? This is often caused when a simplePage tries to embed a content item that it does not know how to handle - as the graphQL query wont have a "... on" query fragment for this content type. Check the CMS simplePage where this error is occuring..')
        }
        referencesMap[referenceID] = reference
    })
    console.log('referencesMap: ', referencesMap)

    let anchorCounter = 0
    const options = {
        // convert \n to <br />
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, 
        text]),
        renderNode: {
            // [BLOCKS.PARAGRAPH]: (node, children) => {
            //     console.log('node: ', node)
            //     return (
            //     <p>{children.split('\n').map((line) => <span key={line}>{line}<br /></span>)}</p>
            // )},
            [INLINES.EMBEDDED_ENTRY]: node => {
                const asset = getReference(referencesMap, node.data.target.sys.id)
                console.log('EMBEDDED_ENTRY asset: ', asset)
                return (
                    <a href={'/' + asset.urlSlug}>{asset.title}</a>
                )
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = getReference(referencesMap, node.data.target.sys.id)
                return (
                    <div>
                        <GatsbyImage alt={asset.title} image={asset.gatsbyImageData} />
                    </div>
                )
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                console.log('HEADING_ children: ', children)
                console.log('HEADING_ node: ', node)
                
                console.log('node.content[0]: ', node.content[0])
                const headingText = reduceContent(node.content)
                console.log ('HEADING_ headingText:', headingText)
            
                anchorCounter = anchorCounter + 1
                return <h3 name={reduceStringToNameAttribute(headingText)} className="custom-heading-3"><a name={reduceStringToNameAttribute(headingText)}>{headingText}</a></h3>;
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                console.log('node: ', node)
                const referenceId = node.data.target.sys.id
                let embed = null
                try {
                    embed = getReference(referencesMap, referenceId)
                } catch (e) {
                    return "unable to find embed asset with id: " + referenceId
                }
                
                console.log('assetID: ', node.data.target.sys.id)
                
                const typename = embed.__typename
                switch(typename) {
                    case "ContentfulSimplePage":
                        return (
                            <>empty123</>
                        )
                    case "ContentfulSocialPost":
                        // render a tiktok post
                        console.log('ContentfulSocialPost embed: ', embed)
                        return (
                            <SocialPost
                                ttUrl={"https://www.tiktok.com/@nenwtomelb/video/" + embed.tiktokPostId}
                                title={embed.name}
                                locationUrl={embed.googleMapsLink}
                             />
                        )
                    case "ContentfulTransportOptions":
                        console.log('ContentfulTransportOptions asset: ', embed)

                        return (
                            <>
                                {/* <HeaderInTOC name={"transport-options"} caption={"TOC - test"} TOC={TOC} /> */}
                                <TransportOptions data={embed} />
                            </>
                        )
                    case "ContentfulImageExtended":
                        console.log('ContentfulImageExtended asset: ', embed)

                        return (
                            <>
                                {/* <HeaderInTOC name={"transport-options"} caption={"TOC - test"} TOC={TOC} /> */}
                                <ContentfulImageExtended locationUrl={embed.locationUrl} title={embed.image.title} imageData={embed.image.gatsbyImageData} />
                            </>
                        )
                    default:
                        return ('unknown embed / asset type: ' + typename)
                }
            },

            // Add render logic for other content blocks as needed
        },
        renderMark: () => {
            return <div>'renderMark'</div>
            // Add render logic for inline elements as needed
        },
    }
    
    // console.log('content: ', JSON.parse(raw))
    const rawParsed = JSON.parse(raw)
    // console.log('rawParsed: ', rawParsed)
    useEffect(() => {
        console.log('useEffect')
        buildTheTOC(rawParsed.content, referencesMap, TOC, setTOC)
    }, [])

    console.log('TOC: ', TOC)
    const components = documentToReactComponents(rawParsed, options)
    return (
        <div>
            <TableOfContents items={TOC} />
            {components}
        </div>
    )
}

// Build an array the Table Of Contents from the contenful simple_page content item type (for rendering later)
const buildTheTOC = (items, referencesMap, TOC, setTOC) => {
    const tempTOC = []
    items.map((item) => {
        console.log('item12: ', item)
        const nodeType = item.nodeType
        let newItem = null

        // add content items (like 'TransportOptions') that need to be rendered in the TOC
        if (nodeType == BLOCKS.EMBEDDED_ENTRY) {
            // check if it's TransportOptions
            const curEmbed = getReference(referencesMap, item.data.target.sys.id)
        
            // console.log('curEmbed: ', curEmbed)
            if(curEmbed.__typename == 'ContentfulTransportOptions') {
                console.log('ContentfulTransportOptions curEmbed: ', curEmbed)

                newItem = {
                    value: TOCaption,
                    name: TOAnchorName
                }
            }
        }
        
        // add H3 items to TOC
        if (nodeType == 'heading-3') {
            newItem = {
                value: stripSlashesAndNewlines(item.content[0].value),
                name: reduceStringToNameAttribute(item.content[0].value)
            }
        }
        
        if (newItem) {
            // console.log('tempTOC: ', tempTOC)
            checkForDuplicates(tempTOC, newItem)
            tempTOC.push(tempTOC, newItem)
            setTOC((prevTOC) => [...prevTOC, newItem])
        }
        
        // console.log('TOC123: ', TOC)
        
        // TOC.push('123')
    })
    return TOC
}

// Check if the name already exists in the TOC array (throw an error if it does).
const checkForDuplicates = (tempTOC, newItem) => {
    // console.log('tempTOC: ', tempTOC)
    tempTOC.map((curItem) => {
        const result = curItem.name == newItem.name
        // console.log('duplicate check: ' + curItem.name + ' == ' + newItem.name + ': ' + result)
        if (result) {
            throw new Error('duplicate Table Of Contents anchor name detected: ' + newItem.name + ". please update the CMS content item to use a different header so the <a> name is also different.")
        }
    })
    return null
}

// reduce a contentful header array down to a string (that can be output into HTML)
// warning: if there's a \n, it will be stripped out and not appear!
const reduceContent = (content) => {
    return content.reduce((acc, curVal) => {
        console.log('curVal: ', curVal)
        if (typeof curVal.value === 'string') {
            return acc + curVal.value
            // return children[0]
        } else {
            return acc
        }
        
    }, "")
}

function stripSlashesAndNewlines(text) {
    // Regular expression for matching slashes and newlines
    const regex = /[\/\n]/g;
  
    // Replace all occurrences with an empty string
    const strippedText = text.replace(regex, '');
  
    return strippedText;
}

const HeaderInTOC = ({ caption, name, TOC }) => {
    
    TOC.push({
        value: caption,
        name: name
    })

    return (
        <h3 name={name}>{caption}</h3>
    )
}

const getReference = ( referencesMap, referenceID ) => {
    const embed = referencesMap[referenceID]

    console.group('in getReference() #[dc1]: ')
    console.log('referenceID: ', referenceID)
    console.log('embed: ', embed)
    console.groupEnd()

    if (!embed) { throw new Error('unable to find reference with ID: ', referenceID)}

    return embed
}